import qs from 'query-string';
import { _sanitiseKeyValues } from '../reducers/handleQueryString';

export const getPaymentTransactionId = () => {
  if (typeof window !== `undefined`) {
    const queryStringObject = qs.parse(window.location.search);
    const sanitisedQueryStringObject = _sanitiseKeyValues(queryStringObject);
    return sanitisedQueryStringObject?.paymentId;
  }
  return '';
};
