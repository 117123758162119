import React from 'react';
import {
  StyledNumberList,
  StyledNumberListItem,
  StyledListItemHeader,
  StyledListItemContent
} from './styles';
import { Paragraph } from '@lux/components-gomo';

export const NumberList = ({ items = [] }) => {
  return (
    <StyledNumberList>
      {items.map((item, index) => {
        return (
          <StyledNumberListItem>
            <StyledListItemHeader>{index + 1}.</StyledListItemHeader>
            <StyledListItemHeader>{item.header}</StyledListItemHeader>
            <StyledListItemContent>
              {typeof item.content === 'string' ? (
                <Paragraph>{item.content}</Paragraph>
              ) : (
                item.content
              )}
            </StyledListItemContent>
          </StyledNumberListItem>
        );
      })}
    </StyledNumberList>
  );
};
