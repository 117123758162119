import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import withLayout from '../components/withLayout';

import { PRODUCT_NAME, PURCHASE_SUCCESS } from '../constants/page_content.json';
import OrderConfirmation from '../containers/OrderConfirmation';

export const SignUpSuccessPage = () => {
  return (
    <Fragment>
      <Helmet defaultTitle={PRODUCT_NAME} titleTemplate="%s">
        <title>{PURCHASE_SUCCESS.TITLE}</title>
      </Helmet>
      <OrderConfirmation />
    </Fragment>
  );
};

export default withLayout(SignUpSuccessPage);
