import React from 'react';
import styled from 'styled-components';
import { remCalc } from '@lux/helpers';
import { Heading, Paragraph, Anchor, Theme } from '@lux/components-gomo';
import AppStoreImgSrc from '../../../assets/images/appstore-badge.png';
import PlayStoreImgSrc from '../../../assets/images/playstore-badge.png';
import { PURCHASE_SUCCESS } from '../../../constants/page_content.json';
import { APP_DOWNLOAD_PAGE } from '../../../constants/links.json';
import { NumberList } from '../../../components/NumberList';
import { isEsim } from '../../../helpers/userDetailsForm';

const BadgeImage = styled(Anchor)`
  display: inline-block;
  text-decoration: none;
  height: 40px;
  margin: ${Theme.spacing.small} 0 0;
  margin-right: ${Theme.spacing.small};

  > img {
    display: inline-block;
    height: 40px;
  }
`;

const Badges = styled.div`
  display: flex;
  margin-top: 4px;
  flex-direction: row;
`;

const StyledNextStepsContainer = styled.div`
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  position: relative;
`;

const StyledNextStepsHeading = styled(Heading)`
  margin: 0 0 16px;
`;

const StyledNextStepsContent = styled.div`
  background-color: ${Theme.colours.gomo_light_blue};
  padding: ${remCalc(24)};

  ${Theme.media.md`
    padding: ${remCalc(24)} ${remCalc(72)};
  `}
`;

const AppBadges = () => (
  <Badges>
    <BadgeImage href={APP_DOWNLOAD_PAGE.IOS} target="_blank">
      <img
        src={AppStoreImgSrc}
        alt={PURCHASE_SUCCESS.CALL_TO_ACTION.DOWNLOAD_FOR_IOS}
      />
    </BadgeImage>
    <BadgeImage href={APP_DOWNLOAD_PAGE.ANDROID} target="_blank">
      <img
        src={PlayStoreImgSrc}
        alt={PURCHASE_SUCCESS.CALL_TO_ACTION.DOWNLOAD_FOR_ANDROID}
      />
    </BadgeImage>
  </Badges>
);

const getEsimReceiptInfo = email => ({
  header: 'Receive eSIM via email',
  content: `We'll send your eSIM and order details to ${email}.`
});

const getSimCardDeliveryInfo = () => ({
  header: 'Receive SIM card',
  content:
    'Your SIM card will be sent to your doorstep at the selected time slot.'
});

const getPopstationPickupInfo = () => ({
  header: 'Collect SIM card',
  content: 'Your SIM card will be sent to your selected POPStation.'
});

const getAppDownloadEsimInfo = () => ({
  header: 'Download GOMO app',
  content: (
    <>
      <Paragraph>You'll need the app to install your eSIM.</Paragraph>
      <AppBadges />
    </>
  )
});

const getAppDownloadPsimInfo = () => ({
  header: 'Activate SIM card',
  content: 'After SIM delivery, we’ll send you an activation link via email.'
});

export const NextStepsDetails = ({ email, simType, isSelfCollection }) => {
  const esimNextSteps = [getEsimReceiptInfo(email), getAppDownloadEsimInfo()];
  const deliveryNextSteps = [
    getSimCardDeliveryInfo(),
    getAppDownloadPsimInfo()
  ];
  const popstationNextSteps = [
    getPopstationPickupInfo(),
    getAppDownloadPsimInfo()
  ];

  let nextSteps = deliveryNextSteps;
  if (isEsim(simType)) {
    nextSteps = esimNextSteps;
  } else if (isSelfCollection) {
    nextSteps = popstationNextSteps;
  }

  return (
    <StyledNextStepsContainer data-testid="next-steps-details">
      <StyledNextStepsContent>
        <StyledNextStepsHeading level={4}>What's next</StyledNextStepsHeading>
        <NumberList items={nextSteps} />
      </StyledNextStepsContent>
    </StyledNextStepsContainer>
  );
};
