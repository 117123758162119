import styled from 'styled-components';
import { Paragraph, Theme } from '@lux/components-gomo';

export const StyledNumberList = styled.div`
  display: grid;
  row-gap: 16px;
`;

export const StyledNumberListItem = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
`;

export const StyledListItemHeader = styled(Paragraph)`
  font-weight: ${Theme.fonts.weight.semiBold};
`;

export const StyledListItemContent = styled.div`
  grid-column: 2;
`;
