import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Heading, Row, Column, Paragraph } from '@lux/components-gomo';
import styled from 'styled-components';
import { PURCHASE_SUCCESS } from '../../constants/page_content.json';
import { NextStepsDetails } from './NextStepsDetails';
import { STORAGE_TYPE, getState } from '../../helpers/persist-state';
import { getPaymentTransactionId } from '../../helpers/orderConfirmation';
import actions from '../../actions';
import {
  GTM_DATALAYER_EVENTS,
  GTM_EVENT_ACTIONS,
  GTM_EVENT_CATEGORIES,
  GTM_EVENT_LABELS
} from '../../constants/gtmDataLayerEvents';
import { pushEventToDataLayer } from '../../actions/dataLayer';

const StyledDescription = styled(Paragraph)`
  padding: 16px 0 32px;
`;

export const OrderConfirmation = ({
  rehydrateSimTypeDetails,
  pushEventToDataLayer
}) => {
  const { email, simType, isSelfCollection, networkType, promoCode } =
    getState('oDetails', STORAGE_TYPE.SESSION_STORAGE) || {};

  const transactionId = getPaymentTransactionId();

  useEffect(
    () => {
      rehydrateSimTypeDetails({ selectedSimType: simType });
    },
    [rehydrateSimTypeDetails, simType]
  );

  useEffect(
    () => {
      if (simType && networkType && transactionId) {
        pushEventToDataLayer({
          event: GTM_DATALAYER_EVENTS.ORDER_COMPLETE,
          eventCategory: GTM_EVENT_CATEGORIES.PAGE_LOAD,
          eventAction: GTM_EVENT_ACTIONS.PAGE_LOAD,
          eventLabel: GTM_EVENT_LABELS.PAGE_LOAD,
          simType,
          networkType,
          couponCode: promoCode,
          transactionId
        });
      }
    },
    [simType, networkType, promoCode, transactionId, pushEventToDataLayer]
  );

  return (
    <>
      <Heading level={2}>{PURCHASE_SUCCESS.HEADING}</Heading>
      <Row>
        <Column sm={12} md={10}>
          <StyledDescription>
            {PURCHASE_SUCCESS.DESCRIPTION[0]}
          </StyledDescription>
        </Column>
      </Row>
      <Row>
        <Column sm={12} md={12}>
          <NextStepsDetails
            email={email}
            simType={simType}
            isSelfCollection={isSelfCollection}
          />
        </Column>
      </Row>
    </>
  );
};

export const mapDispatchToProps = dispatch => {
  return {
    rehydrateSimTypeDetails: data =>
      dispatch(actions.rehydrateSimTypeDetails(data)),
    pushEventToDataLayer: data => dispatch(pushEventToDataLayer(data))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(OrderConfirmation);
